import StorageSvc from '../../services/StorageSvc';

const checkSelectedSources = (item, token, data, isResetData) => {
  // check SSR
  if (typeof window !== 'undefined') {
    if (token && data) {
      return Boolean(item?.split(',')?.sort()?.every((val) => data?.split(',')?.sort()?.includes(val)));
    }

    const sourceFilterLocalStorage = StorageSvc.getItem('state');

    // if there is no any state in localstorage all sources are checked by default
    if (sourceFilterLocalStorage === null || isResetData) return true;

    const loc = JSON.parse(sourceFilterLocalStorage);

    return Boolean(loc.storyFilters?.categories?.includes?.(item));
  }
};

export const filtersStorageKeys = ['ScoreFilter', 'SourceFilter', 'LanguageFilter', 'orderFilter', 'refreshFilter', 'periodFilter'];

export const languagesCodes = 'en,zh,fr,de,it,pl,pt,ru,es,be,bs,bg,hr,cs,lv,lt,mk,cnr,ro,sr,sk,sl,uk,ar,nl,ja,ko,th,tr,sq,af,arm,bn,ca,da,et,fi,ka,el,iw,hi,hu,is,id,kn,mt,ne,no,fa,sv,tl,ur,am,az,eu,bar,bho,my,ceb,fil,gaa,lg,gu,ha,ig,ga,jv,kk,km,rw,ku,ky,lo,ln,lb,mg,ms,ml,mr,mn,ny,or,om,ps,pa,qu,rn,sn,sd,si,so,su,sw,tg,ta,tt,te,ti,tn,tk,uz,vi,cy,wo,xh,yo,zu';

export const refreshValues = [
  { value: '10000', name: '10 secs', premium: true },
  { value: '30000', name: '30 secs', premium: true },
  { value: '60000', name: '1 min', premium: true },
  { value: '600000', name: '10 mins' },
  { value: 'never', name: 'Never' },
];

export const sourcesTabs = [
  {
    id: 1,
    title: 'ALL',
    key: 'all',
  },
  {
    id: 2,
    title: 'Major',
    fullTitle: 'Major sources',
    key: 'major',
    major: true,
  },
  {
    id: 3,
    title: 'Others',
    fullTitle: 'Other Sources',
    key: 'others',
    others: true,
  },
  {
    id: 4,
    title: 'Premium',
    fullTitle: 'Premium Sources',
    key: 'premium',
    is_premium: true,
  },
];

export const refreshValuesConversion = {
  seconds10: '10000',
  seconds30: '30000',
  minute1: '60000',
  minutes10: '600000',
  never: 'never',
};

export const refreshRateConversion = {
  10000: 'seconds10',
  30000: 'seconds30',
  60000: 'minute1',
  600000: 'minutes10',
  never: 'never',
};

export const sourcesValues = [
  {
    title: 'All Sources',
    mobileTitle: 'All',
    id: 1,
    key: 'all',
  },
  {
    title: 'Selected Sources',
    mobileTitle: 'Selected',
    id: 2,
    key: 'whitelist',
  },
  {
    title: 'Blocked Sources',
    mobileTitle: 'Blocked',
    id: 3,
    key: 'blacklist',
  },
];

export const orderValues = [
  { value: 'top', name: 'Top Rated' },
  { value: 'latest', name: 'Latest' },
];

export const orderValuesTab = [
  { id: 'top', content: 'Top' },
  { id: 'latest', content: 'Latest' },
];

export const selectedPeriodValues = [
  { value: 'minutes', name: 'Minutes' },
  { value: 'hours', name: 'Hours' },
  { value: 'days', name: 'Days' },
  { value: 'weeks', name: 'Weeks' },
  { value: 'months', name: 'Months' },
];

export const periodValues = [
  { value: 'minutes5', name: '5 mins' },
  { value: 'minutes15', name: '15 mins' },
  { value: 'hour1', name: '1 hour' },
  { value: 'hours8', name: '8 hours' },
  { value: 'day1', name: '1 day' },
  { value: 'week1', name: '1 week' },
  { value: 'month1', name: '1 month' },
];

export const defaultValues = {
  periodFilterValue: { value: 'day1', name: '1 day' },
};

export const sourceValues = (data, token, isResetData = false) => {
  const values = [
    {
      id: 1,
      sub: [],
      value: 'mp',
      name: 'News Publications',
      select: checkSelectedSources('mp', token, data, isResetData),
    },
    {
      id: 2,
      sub: [],
      name: 'Research',
      value: 'r',
      select: checkSelectedSources('r', token, data, isResetData),
    },
  ];
  return values;
};

export const extraSourceValues = [
  {
    id: 11,
    sub: [],
    name: 'Include sources requiring registration',
    select: true,
  },
  {
    id: 12,
    sub: [],
    name: 'Include sources requiring paid subscription',
    select: true,
  },
];

export const premiumContentFilter = {
  id: 4,
  sub: [],
  name: 'Premium Content ONLY',
  select: false,
  premiumOnly: true,
};

export const majorContentFilter = {
  id: 5,
  sub: [],
  name: 'Major Sources ONLY',
  select: false,
  premiumOnly: false,
};

export const sentimentValues = [
  { id: 1, name: 'Very Positive', value: 'very_positive' },
  { id: 2, name: 'Positive', value: 'positive' },
  { id: 3, name: 'Neutral', value: 'neutral' },
  { id: 4, name: 'Negative', value: 'negative' },
  { id: 5, name: 'Very Negative', value: 'very_negative' },
];

export const sentimentValuesTab = [
  { content: 'All', id: 'all' },
  { content: 'Very Positive', id: 'very_positive' },
  { content: 'Positive', id: 'positive' },
  { content: 'Neutral', id: 'neutral' },
  { content: 'Negative', id: 'negative' },
  { content: 'Very Negative', id: 'very_negative' },
];

export const sentimentValuesByText = {
  very_positive: 'Very Positive',
  positive: 'Positive',
  neutral: 'Neutral',
  negative: 'Negative',
  very_negative: 'Very Negative',
};

export const otherFilters = [
  {
    id: 9,
    key: 'summarization_enabled',
    value: 'Key Headlines',
  },
  {
    id: 1,
    key: 'expand_stories',
    value: 'Expand stories',
  },
  {
    id: 2,
    key: 'user_newsfeed_view_images_web',
    value: 'Images',
  },
  {
    id: 3,
    key: 'user_newsfeed_view_description_web',
    value: 'Description',
  },
  {
    id: 4,
    key: 'story_preview',
    value: 'Story Preview',
  },
  {
    id: 5,
    key: 'group_similar_stories',
    value: 'Group Similar Stories',
  },
  {
    id: 6,
    key: 'show_similar_stories_in_feed',
    value: 'Show Similar Stories in the Feed',
  },
  {
    id: 7,
    key: 'user_newsfeed_view_web',
    value: 'Rows View',
  },
  {
    id: 8,
    key: 'tiles_view',
    value: 'Tiles View',
  },
  {
    id: 9,
    key: 'user_newsfeed_advanced_view_web',
    value: 'Advanced',
  },
  {
    id: 10,
    key: 'isPremiumLabelsEnabled',
    value: 'Labels for Premium features and content',
  },
];

export const priorityLanguagesShortName = ['en', 'zh', 'fr', 'de', 'it', 'pl', 'pt', 'ru', 'es'];

export const allFilters = [
  {
    refresh_rate: 'Auto Refresh',
    order_by: 'Story Order',
    time_filter: 'Time Period',
    categories: 'Story Sources',
    twitter_following_only: 'Twitter Following Only',
    with_links: 'Tweets only with links i.e. no opinions',
    min_score: 'CityFalcon Minimum Score',
    languages: 'Languages',
    user_newsfeed_advanced_view_web: 'Advanced Web View',
    user_newsfeed_view_web: 'News Feed View',
    user_newsfeed_view: 'News Feed View',
    user_newsfeed_compactview_web: 'News Feed View',
    user_newsfeed_compactview: 'News Feed View',
    show_similar_stories_in_feed: 'Show Similar Stories',
    group_similar_stories: 'Group Similar Stories',
    selected_time_filter: 'Selected Time Filter',
    summarization_enabled: 'Summarisation',
    expand_stories: 'Expand Stories',
    user_newsfeed_view_images_web: 'News Feed View Images',
    user_newsfeed_view_description_web: 'News Feed View Description',
    sentiment_categories: 'Sentiment Categories',
    sentiment_down_limit: 'Sentiment Down Limit',
    sentiment_up_limit: 'Sentiment Up Limit',
    paywall: 'Include sources which require payment',
    registrationRequired: 'Include sources which require registration',
    major_sources_only: 'Show Major Sources Only',
    premium_content_only: 'Show Premium Content Only',
    sentiment_filter: 'Sentiment Filter',
    isPremiumLabelsEnabled: 'Labels for Premium features and content',
  },
];
export const initialState = {
  stories_per_page: 30,
  categories: 'mp,op,r',
  order_by: 'latest',
  time_filter: 'month1',
  languages: [
    ...new Set([
      'en',
      typeof window === 'object' ? window.navigator.language.split('-')[0] : 'en',
    ]),
  ].join(','),
  selected_time_filter: 'predefined',
  min_score: 5,
  asset_classes: '',
  sectors: '',
  industries: '',
  subindustries: '',
  startup_categories: '',
  segments: '',
  events: '',
  summarization_enabled: true,
  query: '',
  twitter_following_only: false,
  with_links: false,
  translate_to: null,
  required_source: {
    registrationRequired: true,
    paywall: true,
  },
  expand_stories: false,
  user_newsfeed_view: 'tiles',
  user_newsfeed_view_description: false,
  user_newsfeed_view_images: true,
  user_newsfeed_compactview: false,
  user_newsfeed_compactview_web: false,
  user_newsfeed_view_description_web: false,
  user_newsfeed_view_images_web: true,
  user_newsfeed_advanced_view_web: false,
  user_newsfeed_advanced_view: false,
  user_newsfeed_view_web: 'rows',
  group_similar_stories: true,
  show_similar_stories_in_feed: true,
  tiles_view: false,
  refresh_rate: '600000',
  show_trending_asset_bar: false,
  refresh_counter: 0,
  empty_selected_trending_filters: false,
  all_languages: false,
  userStartSelectAssetClasses: false,
  translate_all: false,
  conflictsChecked: false,
  premium_content_only: false,
  domains_filtering_mode: 'all',
  whitelisted_domain_ids: [],
  blacklisted_domain_ids: [],
  filings_time_filter: 'lifetime',
  filing_period_start_datetime: null,
  filing_period_end_datetime: null,
  filing_type_ids: [],
  filing_category_ids: [],
  filing_source_ids: [],
  expand_filings: false,
  filing_types_all: true,
  filing_categories_all: true,
  filing_sources_all: true,
  sentiment_filter: 'categories',
  sentiment_categories: ['very_negative', 'negative', 'neutral', 'positive', 'very_positive'],
  sentiment_down_limit: -100,
  sentiment_up_limit: 100,
  selected_order_tab: 'top',
  selected_sentiment_tab: 'all',
  isPremiumLabelsEnabled: true,
  isOnboardingProcess: false,
  translationSupportedLanguages: [],
};

export const UPDATE_SCROLL_BOTTOM_DISTANCE = 2000;

export const STORIES_LIMIT = 20;
