// helper functions for watchlists
import filter from 'lodash-es/filter';
import pluralize from 'pluralize';
import { renderToStaticMarkup } from 'react-dom/server';

import {
  NEWS, FILINGS, CHARTS, INVESTOR_RELATIONS, ESG, INSIDER_TRANSACTIONS,
  DCSC_SECTORS,
} from '../data/directory/topic_classes/tabPaths';

export function isQueryValid(query) {
  const negativeOperator = 'NOT';
  const logicalOperators = ['AND', 'OR'];
  const pieces = String(query).trim().split(' ').filter(Boolean);

  if (
    logicalOperators.includes(pieces[0])
    || logicalOperators.includes((pieces[pieces.length - 1]))
    || pieces[pieces.length - 1] === negativeOperator
  ) {
    return false;
  }

  return pieces.every((piece, i) => {
    // if piece is NOT operator and it have no AND or OR operator before it
    // or if AND or OR comes after NOT operator
    // then query is invalid
    if (
      piece === negativeOperator
      && (
        (i !== 0 && !logicalOperators.includes(pieces[i - 1]))
        || logicalOperators.includes(pieces[i + 1])
      )
    ) {
      return false;
    }

    // if double logical operator used with each other
    if (logicalOperators.includes(piece) && logicalOperators.includes(pieces[i - 1])) {
      return false;
    }

    return true;
  });
}

export function generateQuery(query) {
  if (!isQueryValid(query)) return `"${query}"`;

  // Replace all double quotes with single quotes
  const replacedStr = query.replace(/"/g, "'");

  /*
    Split with AND & OR to get the word for checking if the words start with the quotes or not
  */
  const newQuery = replacedStr.split(' AND ')
    .map((splitStr) => splitStr
      .split(' OR ')
      .map((str) => {
        const trimStr = str.trim();
        const isContain = trimStr.match(/^NOT\s/);
        if (isContain) {
          const newStr = trimStr.replace(/^NOT\s/, '');
          if (newStr.match(/^'(.*?)'$/)) {
            return newStr.replace(/(?!^')'(?!$)/g, "\\'").replace(/^/, 'NOT ');
          }
          return newStr.replace(/"|'/g, "/'").replace(/^/, 'NOT ');
        }
        if (trimStr.match(/^'(.*?)'$/)) {
          return trimStr.replace(/(?!^')'(?!$)/g, "\\'");
        }
        return trimStr.replace(/"|'/g, "/'");
      })
      .join(' OR '))
    .join(' AND ');

  return (
    `"${newQuery}"`
      .replace(/NOT /g, 'NOT "')
      .replace(/NOT "'/g, "NOT '")
      .replace(/\s*AND\s*/g, '" AND "')
      .replace(/'" AND/g, "' AND")
      .replace(/AND "'/g, "AND '")
      .replace(/\s*OR\s*/g, '" OR "')
      .replace(/'" OR/g, "' OR")
      .replace(/OR "'/g, "OR '")
      .replace(/"NOT/g, 'NOT')
      .replace(/'NOT/g, 'NOT')
      .replace(/^"'/, "'")
      .replace(/'"$/, "'")
  );
}

// formatter for entity types names of topics
export const transformWatchlistTopicType = (topicType) => {
  let convertedType = topicType;

  if (topicType === 'Exchange Traded Funds (ETFs)') convertedType = 'ETF';
  if (topicType === 'Financial Regulation and Related Topics') convertedType = 'Financial Regulation';
  if (topicType === 'Major Business and Related Activities') convertedType = 'Event';
  if (topicType === 'Ratings and Research Firms') convertedType = 'Ratings or Research Firm';
  if (topicType === 'StartupCategory') convertedType = 'Category';
  if (topicType === 'Foreign Exchange and Currencies') convertedType = 'Foreign Exchange or Currency';
  if (topicType === 'Private Companies and Brands') convertedType = 'Private Company or Brand';
  if (topicType === 'Sovereign and Government Bonds') convertedType = 'Sovereign or Government Bond';
  if (topicType === 'AssetClass') convertedType = 'Topic Class';

  return pluralize(convertedType, 1);
};

export const checkOneTopicCheck = (topics) => {
  const items = filter(topics, ['hidden', false]);
  return items.length <= 1;
};

export const getTrendingBarAssetQuery = (topic, type) => {
  const { id, name } = topic;
  try {
    if (type === 'Topic Class') {
      return `"group:AssetClass:${id}:${name}"`;
    }

    return `"group:${type}:${id}:${name.replace(/\((.*?)\)/g, '\\($1\\)')}"`;
  } catch (err) {
    return undefined;
  }
};

// helper method for adding simple topics to wl
export const formatTopicsQuery = (topics, userQuery) => {
  try {
    const queries = topics.map((topic) => {
      if (topic.logical_structure && topic.query) {
        return topic.logical_structure?.original ?? topic.query;
      }

      if (!topic.groups) {
        if (topic?.itemType === 'AssetClass') {
          return `"group:AssetClass:${topic.id}:${topic.name}"`;
        }

        const selectedQuery = topic.query || topic.name;
        let quotedQuery;

        if (/\b(?:AND|OR|NOT)\b/g.test(selectedQuery)) {
          quotedQuery = generateQuery(selectedQuery);
        } else {
          // check if word is inside single quotes
          if (selectedQuery.match(/^'(.*?)'$/)) {
            // Leave first and last single quote and replace other with the escape sign
            quotedQuery = selectedQuery.replace(/(?!^')'(?!$)/g, "\\'");
          }
          if (selectedQuery.match(/^"(.*?)"$/g)) {
            // Check if word is inside of double quotes
            quotedQuery = selectedQuery.replace(/"/g, "'").replace(/(?!^')'(?!$)/g, "\\'");
          } else {
            // if query doesn't have logical operators, return it
            quotedQuery = selectedQuery.replace(/\((.*?)\)/g, '\\($1\\)').replace(/"/g, '\\"');
          }
        }
        return quotedQuery;
      }

      const [{ groupName, groupType }] = topic.groups;

      let name = '';
      if (topic.topicName) name = topic.topicName.replace(/\((.*?)\)/g, '\\($1\\)');
      if (topic.name) {
        if (topic.groups.length) {
          // check if word is inside single quotes
          if (topic.name.match(/^'(.*?)'$/)) {
            // Leave first and last single quote and replace other with the escape sign
            name = topic.name.replace(/(?!^')'(?!$)/g, "\\'");
          } else if (topic.name.match(/^"(.*?)"$/g)) {
            // Check if word is inside of double quotes
            name = topic.name.replace(/"/g, "'").replace(/(?!^')'(?!$)/g, "\\'");
          } else {
            // if query doesn't have logical operators, return it
            name = topic.name.replace(/\((.*?)\)/g, '\\($1\\)').replace(/"/g, '\\"');
          }
        } else {
          // detect if there is logical AND OR operators in the query, split it, add "" and join
          name = topic.name.split(' ').map((e) => (['AND', 'OR', 'NOT'].includes(e) ? e : `"${e}"`)).join(' ');
        }
      }
      if (topic.query) name = topic.query;

      return `"group:${groupType}:${groupName}:${(name)}"`;
    });

    if (userQuery) {
      let quotedQuery = '';

      if (/\b(?:AND|OR|NOT)\b/g.test(userQuery)) {
        quotedQuery = generateQuery(userQuery);
      } else {
        // check if word is inside single quotes
        if (userQuery.match(/^'(.*?)'$/)) {
          // Leave first and last single quote and replace other with the escape sign
          quotedQuery = userQuery.replace(/(?!^')'(?!$)/g, "\\'");
        }
        if (userQuery.match(/^"(.*?)"$/g)) {
          // Check if word is inside of double quotes
          quotedQuery = userQuery.replace(/"/g, "'").replace(/(?!^')'(?!$)/g, "\\'");
        } else {
          // if query doesn't have logical operators, return it
          quotedQuery = userQuery.replace(/\((.*?)\)/g, '\\($1\\)').replace(/"/g, '\\"');
        }
      }

      queries.push(quotedQuery);
    }

    return `${queries.join(' ;; ')}`;
  } catch (err) {
    return undefined;
  }
};

// parser for displaying correct names of watchlist topics
export const queryItemWatchlistParser = (assetQuery) => {
  const name = assetQuery?.split('" OR "').map((item) => {
    if (item?.split(':')[3]) {
      return item.split(':')[3].replace(/["]/g, '');
    }
    return item;
  }) || [];

  if (name.length > 1) {
    return { name: name.join(' OR ').replace(/["\\]/g, ''), type: 'Query' };
  }

  if (assetQuery?.split(':')[3]) return { name: assetQuery?.split(':')[3].replace(/["\\]/g, '') };
  if (assetQuery?.match(/""(.*?)""/g)) return { name: `"${assetQuery.replace(/[\\]/g, '')}"` };
  return { name: assetQuery.replace(/[\\]/g, '') };
};

export const getSharedIdentifierFromUrl = (hasValue = '') => {
  const identifier = window.location.pathname.split('/')[2];
  const isIdentifier = ![
    'watchlists',
    NEWS,
    FILINGS,
    CHARTS,
    INVESTOR_RELATIONS,
    ESG,
    INSIDER_TRANSACTIONS,
    DCSC_SECTORS,
  ].includes(identifier);

  if (isIdentifier) {
    // removing identifier from url to prevent adding shared watchlist again on page reload
    window.history.pushState({}, document.title, `${window.location.origin}/watchlists${hasValue}`);
  }

  return isIdentifier ? identifier : null;
};

// parsing registered user watchlist queries to
// guest user watchlist queries during watchlist sharing
export const parseSearchQuery = (searchQuery) => {
  if (searchQuery.includes('group', 1)) {
    return {
      id: searchQuery.split(':')[2],
      query: searchQuery.split(':')[3].replace(/["]/g, ''),
      groups: [
        {
          groupName: searchQuery.split(':')[2],
          groupType: searchQuery.split(':')[1],
        },
      ],
    };
  }

  return {
    id: Math.floor(Math.random() * 1000),
    query: searchQuery.replace(/["]/g, ''),
  };
};

// select all topics in watchlist
export const selectAllWatchlistTopics = ([activeWatchlist]) => ({
  ...activeWatchlist,
  search_queries: activeWatchlist?.search_queries?.map((item) => ({
    ...item,
    hidden: false,
  })),
  watchlist_assets: activeWatchlist?.watchlist_assets?.map((item) => ({
    ...item,
    hidden: false,
  })),
});

export const parseQueryLogicalStructure = (structure, useJSX = false, width) => {
  const operators = ['AND', 'OR', 'NOT'];
  const operatorStyle = {
    fontSize: width >= 2800 ? '20px' : '10px',
    padding: width >= 2800 ? '0 30px' : '0 15px',
  };
  const operatorNotStyle = {
    ...operatorStyle,
    paddingLeft: 0,
    marginLeft: width >= 2800 ? '-20px' : '-10px',
  };
  const operatorValueStyle = {
    fontFamily: '\'Lato\', sans-serif',
    fontSize: width >= 2800 ? '28px' : '14px',
    fontWeight: '400',
  };
  const operatorTypeStyle = {
    opacity: 0.8,
    fontFamily: '\'Lato\', sans-serif',
    fontSize: width >= 2800 ? '18px' : '10px',
    letterSpacing: width >= 2800 ? '1px' : '0.5px',
  };
  const parenthesesStyle = {
    fontFamily: '\'Lato\', sans-serif',
    fontSize: width >= 2800 ? '44px' : '22px',
    fontWeight: '400',
    lineHeight: '0.9',
  };
  const leftParenthesesStyle = {
    ...parenthesesStyle,
    paddingRight: width >= 2800 ? '20px' : '10px',
  };
  const rightParenthesesStyle = {
    ...parenthesesStyle,
    paddingLeft: width >= 2800 ? '20px' : '10px',
  };

  if (structure instanceof Object) {
    const operator = operators.find((operator) => (
      structure.hasOwnProperty(operator)
    ));

    if (operator) {
      if (operator === 'NOT') {
        return useJSX ? (
          <div className="d-flex align-items-center">
            <div style={operatorNotStyle}>{operator}</div>
            {parseQueryLogicalStructure(structure[operator], useJSX, width)}
          </div>
        ) : `${operator} ${parseQueryLogicalStructure(structure[operator], useJSX, width)}`;
      }

      const queries = structure[operator].map((item) => (
        parseQueryLogicalStructure(item, useJSX, width)
      ));

      return useJSX ? (
        queries.map((query, index) => {
          const queryString = renderToStaticMarkup(query);
          const showParentheses = (
            (operator === 'OR' && queryString.includes('>AND<'))
            || (operator === 'AND' && queryString.includes('>OR<'))
          );

          return (
            // eslint-disable-next-line react/no-array-index-key
            <div className="d-flex align-items-center" key={index}>
              {showParentheses && (
                <span style={leftParenthesesStyle}>(</span>
              )}
              {query}
              {showParentheses && (
                <span style={rightParenthesesStyle}>)</span>
              )}
              {queries.length !== index + 1 && (
                <div style={operatorStyle}>
                  {operator}
                </div>
              )}
            </div>
          );
        })
      ) : queries.join(` ${operator} `);
    }

    let type = /^topic$/i.test(structure.group_type || '') ? structure?.asset_class?.title || structure.group_type : (structure.group_type || 'Query');
    type = pluralize.singular(transformWatchlistTopicType(type));
    return useJSX ? (
      <div>
        <div style={operatorValueStyle}>{structure.value.replace(/\\|\//g, '')}</div>
        <div style={operatorTypeStyle}>
          {type}
        </div>
      </div>
    ) : (
      structure.value
    );
  }

  return '';
};

// check duplicates in watchlist names
export const checkDuplicates = (watchlistsArray, newName) => {
  const indexWatchlistName = watchlistsArray.findIndex((watchlist) => watchlist.name === newName);

  return indexWatchlistName !== -1;
};

// helpers for find & rename duplicates name
// when user try to rename WL name it is necessary to check duplicates
export const checkWLNames = (watchlistsArray, newName, id) => {
  const watchlistsNames = watchlistsArray.map((watchlist) => watchlist.name);
  if (watchlistsNames.indexOf(newName) === -1) return;

  const neededIndex = watchlistsArray.findIndex((watchlist) => watchlist.id === id);
  watchlistsNames.splice(neededIndex, 1);
  watchlistsNames.push(newName);

  // find and rename duplicates
  const count = {};
  watchlistsNames.forEach((name, index) => {
    if (watchlistsNames.indexOf(name) !== index) {
      const currentCount = name in count ? count[name] += 1 : count[name] = 1;
      const prefix = currentCount + 1;
      let newWLName = `${name} (${prefix})`;

      while (watchlistsNames.indexOf(newWLName) !== -1) {
        const newPrefix = prefix + 1;
        newWLName = `${name} (${newPrefix})`;
      }
      watchlistsNames[index] = newWLName;
    }
  });
  const lastElement = watchlistsNames.pop();
  return lastElement;
};

// check name Watchlists with special characters
export const isSpecialCharacters = (value) => {
  const valueTrimed = value.trim();

  if (valueTrimed.match(/[!"`'#%&,.:;<>=@{}~\$\*\+\/\\\?\[\]\^\|]+/)) return true;
  return false;
};

export const checkedDuplicateWatchlistName = (name, userWatchlists = []) => {
  const hasDuplicate = [...userWatchlists].filter((wl) => wl.name === name);
  if (hasDuplicate?.length) return null;
  return name;
};

// get default name for watchlist during creating wl on manage watchlists page
export const getDefaultWatchlistName = (watchlistsArray) => {
  if (watchlistsArray?.length) {
    let number = watchlistsArray?.length;
    let name = `Watchlist${number}`;
    while (checkedDuplicateWatchlistName(name, watchlistsArray) === null) {
      number += 1;
      name = `Watchlist${number}`;
    }
    return name;
  }
  return 'Watchlist1';
};

export const compareAndGetWatchlistsWithoutNameConflicts = (userWatchlists = [], guestWatchlists = []) => {
  const copyGuestsWatchlists = [...guestWatchlists];
  const copyUserWatchlists = [...userWatchlists];

  const checkedArray = [];

  copyGuestsWatchlists?.forEach((guestWl) => {
    const arrayDuplicates = copyUserWatchlists.filter((userWl) => userWl.name === guestWl.name);

    if (!arrayDuplicates?.length) {
      checkedArray.push(guestWl);
      return;
    }

    if (arrayDuplicates?.length) {
      const nameForChecking = copyUserWatchlists.length + 1;
      const checkedName = copyUserWatchlists.filter((userWl) => userWl.name === nameForChecking);

      if (!checkedName?.length) {
        if (arrayDuplicates[0]?.name.includes('Watchlist')) {
          checkedArray.push({
            ...guestWl,
            name: `Watchlist${copyUserWatchlists.length + 1}`,
          });
          copyUserWatchlists.push({
            ...guestWl,
            name: `Watchlist${copyUserWatchlists.length + 1}`,
          });
          return;
        }
        checkedArray.push({
          ...guestWl,
          name: checkWLNames(copyUserWatchlists, guestWl.name, guestWl.id),
        });
        copyUserWatchlists.push({
          ...guestWl,
          name: checkWLNames(copyUserWatchlists, guestWl.name, guestWl.id),
        });
      }
    }
  });

  return checkedArray;
};

export const checkOnValidBracketsName = (name) => {
  const charactersWithoutBracket = name?.trim()?.split('').filter((character) => (character !== '(') && (character !== ' ') && (character !== ')'));

  if (charactersWithoutBracket?.length) return false;

  return true;
};
