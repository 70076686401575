// copy-ing object
export function copy(object) {
  if (!object || typeof object !== 'object') return object;

  const result = new object.constructor();
  Object.entries(object).forEach(([prop, value]) => {
    result[prop] = copy(value);
  });
  return result;
}

export default copy;
