import { Component as ComponentClass, forwardRef } from 'react';
import { findDOMNode } from 'react-dom';

export default (Component) => {
  class WithComponentName extends ComponentClass {
    componentDidMount() {
      let displayName;
      if (Component.WrappedComponent) {
        displayName = Component.WrappedComponent.displayName
          || Component.WrappedComponent.name;
      } else {
        displayName = Component.displayName
          || Component.name
          || 'UnnamedComponent';
      }

      // eslint-disable-next-line react/no-find-dom-node
      const node = findDOMNode(this);
      if (displayName && node && node.dataset) node.dataset.component = displayName;
    }

    render() {
      const { forwardedRef, ...rest } = this.props;

      return (
        <Component ref={forwardedRef} {...rest} />
      );
    }
  }

  const RefWrapper = forwardRef((props, ref) => (
    <WithComponentName {...props} forwardedRef={ref} />
  ));

  RefWrapper.displayName = WithComponentName.displayName;

  return RefWrapper;
};
