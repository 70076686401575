const localStorageMock = {
  storage: new Map(),
  getItem(key) {
    return this.storage.get(key) ?? null;
  },
  setItem(key, value) {
    return this.storage.set(key, value);
  },
  removeItem(key) {
    this.storage.delete(key);
  },
  clear() {
    this.storage.clear();
  },
};
let localStorage;
try {
  localStorage = typeof window === 'object' && window.localStorage ? window.localStorage : localStorageMock;
} catch {
  localStorage = localStorageMock;
}

class StorageSvc {
  static getItem(key) {
    if (typeof key !== 'string') {
      return null;
    }

    try {
      return localStorage.getItem(key);
    } catch (e) {
      console.warn(e);
      return null;
    }
  }

  static setItem(key, value) {
    if (typeof key !== 'string') {
      return;
    }

    try {
      localStorage.setItem(key, value);
    } catch (e) {
      console.warn(e);
      this.removeItem(key);
    }
  }

  static removeItem(key) {
    if (typeof key !== 'string') {
      return;
    }

    try {
      localStorage.removeItem(key);
    } catch (e) {
      console.warn(e);
    }
  }

  static clear() {
    try {
      localStorage.clear();
    } catch (e) {
      console.warn(e);
    }
  }
}

export default StorageSvc;
